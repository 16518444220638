import React from 'react';
import {Input} from "./Form";
import styled from "styled-components";

const InputComLabel = ({label, ...props}: any) => {

    return (
        <div>
            <Label>{label}</Label>
            <Input {...props} />
        </div>
    );
};

export default InputComLabel;


const Label = styled.label`
font-size: 1.4rem;
font-weight: 400;
margin-bottom: .2rem;
display: block;
`
