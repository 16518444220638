import {history} from './storeRoutes'
import {ConnectedRouter} from 'connected-react-router'
import {Switch, Route, Redirect} from 'react-router-dom'
import React from "react";
import MenuPage from "../pages/MenuPage";
import ProdutoPage from "../pages/ProdutoPage";
import NavigationHelper from "./NavigationHelper";
import PagamentoPage from "../pages/PagamentoPage";
import NotFound from "../pages/NotFound";

const RoutesConfig = (props: any) => {
    return (
        <ConnectedRouter history={history}>
            <NavigationHelper />
            <Switch>
                <Route path={'/:empresa/menu/:id'} component={ProdutoPage} />
                <Route path={'/:empresa/menu'} component={MenuPage} />
                <Route path={'/:empresa/pagamento'} component={PagamentoPage} />
                <Route path={'/404'} component={NotFound} />
                <Redirect path={'/:empresa'} exact to={'/:empresa/menu'} />
                <Redirect path={'/'} exact to={'/404'} />
            </Switch>
        </ConnectedRouter>
    )
}

export default RoutesConfig
