import React from 'react';
import {Provider} from 'react-redux';
import store from './store'
import RoutesConfig from './config/RoutesConfig';
import DrawerContextProvider from "./contexts/DrawerContext";
import OrderContextProvider from "./contexts/OrderContext";
import ModalContextProvider from "./contexts/ModalContext";
import ApiDataContextProvider from "./contexts/ApiDataContext";
import HtmlHeaders from "./config/HtmlHeaders";
import {HelmetProvider} from 'react-helmet-async';
import LoginContextProvider from "./contexts/LoginContext";

function App() {

// @ts-ignore
    window.FontAwesomeConfig = {
        searchPseudoElements: true
    }

    return (
        <Provider store={store}>
            <LoginContextProvider>
                <ApiDataContextProvider>
                    <HelmetProvider>
                        <HtmlHeaders/>
                    </HelmetProvider>
                    <DrawerContextProvider>
                        <OrderContextProvider>
                            <ModalContextProvider>
                                <RoutesConfig/>
                            </ModalContextProvider>
                        </OrderContextProvider>
                    </DrawerContextProvider>
                </ApiDataContextProvider>
            </LoginContextProvider>
        </Provider>
    );
}

export default App;
