import React from 'react';
import styled from "styled-components";
import {Column, Row} from "../styles/flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {colorDark} from "../styles/variables";
import {Radio} from "./Form";

const AddressRepresentation = ({deletar, onClick, address, ...props}: any) => {
    return (
        <InputContainer>
        <AddressContainer>
            <FontAwesomeIcon onClick={deletar} icon={faTrashAlt} style={{cursor: 'pointer'}} size={'2x'}/>
            <AddressDescription onClick={onClick}>
                <Top>{`${address.logradouro}, ${address.numero} - ${address.complemento}`}</Top>
                <Bottom>{`${address.bairro}, ${address.cidade}/${address.uf} - CEP ${address.cep}`}</Bottom>
            </AddressDescription>
        </AddressContainer>
            <Radio {...props} />
        </InputContainer>
    );
};

export default AddressRepresentation;

const InputContainer = styled.div`
${Row};
`

const AddressDescription = styled.div`
${Column};
align-items: flex-start;
`

const AddressContainer = styled.div`
${Row};
justify-content: stretch;
color: ${colorDark};
margin-bottom: 2rem;

${AddressDescription} {
margin-left: 2rem;
}
`

const Top = styled.div`
font-size: 1.6rem;
font-weight: bold;
`
const Bottom = styled.div`
font-size: 1.2rem;
`

