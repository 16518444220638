export const getHoutAndMinute = (data: Date): string => {
    const hour = addZero(data.getHours().toString())
    const minute = addZero(data.getMinutes().toString())
    return `${hour}:${minute}`
}

export const addZero = (v: string): string => {
    if(v.length === 1) v = `0${v}`
    return v
}
