import axios from 'axios'

const api = axios.create({
    validateStatus: status => status >= 200 && status < 300,
})

export const setToken = (token: string) => {
    api.defaults.headers.common['Authorization'] = token
}

export default api