import React, {useContext} from 'react';
import styled, {css} from "styled-components";
import {DrawerContext} from "../contexts/DrawerContext";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {Categoria} from "../models/Categoria";
import {Column} from "../styles/flex";
import {colorDark} from "../styles/variables";

const Drawer = () => {

    const {isOpen, closeDrawer}: any = useContext(DrawerContext)
    const {categories, categoriaSelecionada, setCategoriaSelecionada, parametros}: any = useContext(ApiDataContext)

    const handleCategoriaClicked = (categoria: Categoria) => {
        setCategoriaSelecionada(categoria)
        closeDrawer()
    }

    return (
        <DrawerContainer isOpen={isOpen} onClick={closeDrawer}>
            <DrawerStyle isOpen={isOpen} onClick={(e: Event) => {
                e.stopPropagation()
            }}>
                <DrawerContent>
                    {categories.map((categoria: Categoria) => <DrawerItem key={categoria.id} onClick={() => handleCategoriaClicked(categoria)}
                    active={categoria.id === categoriaSelecionada.id} color={parametros?.idWebTemplate?.cor}>{categoria.name}</DrawerItem>)}
                </DrawerContent>
            </DrawerStyle>
        </DrawerContainer>
    );
};

const DrawerContainer: any = styled.div`
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
left: 0;
z-index: 100;
${(props: any) => props.isOpen ? ({
    backgroundColor: 'rgba(0,0,0, .3)',
    transition: 'all .5s',
}) : ({
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    transition: 'all .5s',
})
};
`

const DrawerClose = css`
width: 0;
transition: all .5s;
`

const DrawerOpen = css`
width: 80vw;
transition: all .5s;
@media(min-width: 768px) {
width: 40rem;
}
`

const DrawerStyle: any = styled.div`
height: 100vh;
${(props: any) => props.isOpen ? DrawerOpen : DrawerClose};
background-color: #FFF;
position: fixed;
top: 0;
left: 0;
z-index: 200;
overflow: hidden;
`

const DrawerContent = styled.div`
${Column};
padding-top: 2rem;
`

const DrawerItem: any = styled.div`
padding: 2rem;
font-size: 1.4rem;
cursor: pointer;
white-space: nowrap;
${({active, color}: any) => active ? ({color}) : ({color: colorDark})}
`

export default Drawer;
