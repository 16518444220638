import React, {useContext, useEffect, useState} from 'react';
import {AppBar} from "../components/AppBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck, faCreditCard, faDollarSign, faEdit, faMoneyBillAlt} from "@fortawesome/free-solid-svg-icons";
import MaxWidth from "../layouts/MaxWidth";
import {Endereco, Order, OrderContext, PedidoItem} from "../contexts/OrderContext";
import {CardFooter} from "../components/Footer";
import styled from "styled-components";
import {bodyColorDark, colorDark, colorDarkMedium, colorGreen} from "../styles/variables";
import {PrimaryButton, TransparentButton} from "../components/Button";
import {Column, ColumnComponent, Row} from "../styles/flex";
import ProductPriceComCifrao from "../components/ProductPriceComCifrao";
import {Padding} from "../styles/padding";
import {Observation} from "../components/DivisionSubtitle";
import {Formik} from "formik";
import {SubTitle, TextBig1, TextSmall} from "../styles/typography";
import {Input, Radio} from "../components/Form";
import InputComLabel from "../components/InputComLabel";
import ModalPagamentos from "../modais/ModalPagamentos";
import {ModalContext} from "../contexts/ModalContext";
import InputComMascara from "../components/InputComMascara";
import axios from 'axios'
import * as Yup from 'yup'
import {getHoutAndMinute} from "../util/date_util";
import {addAdress, deleteAddress, getAddress, listAddress} from "../config/database";
import AddressRepresentation from "../components/AddressRepresentation";
import {sub, sum} from "../util/math_util";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {Parametros} from "../models/Parametros";


const handleOrder = async (pedido: Order, parametros: Parametros, frete: number, total: number) => {
    const link = []
    link.push(`https://wa.me/55${parametros.whattsAppLoja}?text=`)
    link.push(`*${parametros.nomeLoja} - Novo Pedido*%0A`)
    link.push('---------------------------------------%0A%0A')
    pedido.pedidoItens.forEach(item => {
        let text = `*${item.amount}x ${item.product.name}`
        if(parametros.exibePrecoPedido) {
            text = text + ` - ${item.total}`
        }
        text = text + `*%0A`
        link.push(text)
    })
    link.push('*Tipo de Entrega*%0A')
    link.push(`${pedido.tipoEntrega}%0A%0A`)

    if (pedido.tipoEntrega === 'delivery' && pedido.endereco !== undefined) {
        const {cep, logradouro, bairro, cidade, complemento, numero, uf} = await getAddress(pedido.endereco)

        link.push('*Qual seu endereço?*%0A')
        link.push(`${logradouro} ${numero} ${complemento}%0A`)
        link.push(`${bairro} ${cidade}/${uf}%0A`)
        link.push(`${cep}%0A%0A`)
    }

    link.push('*Como você vai pagar?*%0A')
    link.push(`${pedido.pagamento.meioPagamento}%0A%0A%0A`)

    if(parametros.exibePrecoPedido) {
        let valorItens = frete ? sub(total, frete) : total
        link.push('*Total Itens:*%0A')
        link.push(`${valorItens}%0A`)
        link.push('*Frete:*%0A')
        link.push(`${frete}%0A`)
        link.push('*Total Geral:*%0A')
        link.push(`${total}%0A%0A%0A`)
    }

    if (pedido.pagamento.meioPagamento === 'dinheiro') {
        link.push('*Troco para quanto?*%0A')
        link.push(`${pedido.pagamento.valor}%0A%0A`)
    }

    link.push(' *Nome:*%0A')
    link.push(`${pedido.nomeCliente}%0A%0A`)
    link.push('*Telefone:*%0A')
    link.push(`${pedido.telefone}%0A%0A%0A`)

    if(parametros.obsPedido) {
        link.push('*Obervações:*%0A')
        link.push(`${pedido.observacoes}%0A%0A%0A`)
    }

    link.push(`_Pedido recebido pelo Super Zapp às ${getHoutAndMinute(new Date())}_`)

    window.open(link.join(''))
}

const formSchema = Yup.object().shape({
    tipoEntrega: Yup.string().required(),
    nomeCliente: Yup.string().required(),
    telefone: Yup.string().required(),
    pagamento: Yup.object().shape({
        meioPagamento: Yup.string().required(),
    })
})

const PagamentoPage = ({history}: any) => {

    let updatePayment

    const {order: currentOrder, changePaymentOrder, removeAddress}: any = useContext(OrderContext)
    const {pedidoItens} = currentOrder

    const {openModal: openModalPagamento, setInitialData: pagamentoEditData}: any = useContext(ModalContext)
    const {parametros}: {parametros: Parametros} = useContext(ApiDataContext)

    const [total, setTotal] = useState(0)
    const [frete, setFrete] = useState(0)

    const [isEditingAddress, setIsEditingAddress] = useState(false)

    const [addressSaved, setAddressSaved]: [Endereco[], any] = useState([])

    const fetchAddress = async () => {
        const address = await listAddress()
        setAddressSaved(address)
    }

    useEffect(() => {
        fetchAddress()
    }, [])

    useEffect(() => {
        let totalItems = pedidoItens.map((v: PedidoItem) => v.total).reduce((acc: number, cur: number) => sum(acc, cur), 0)
        if(parametros?.taxaEntrega) {
            if(parametros?.entregaGratis && parametros?.entregaGratisAcimaDe <= totalItems) {
                setFrete(0)
                return
            }
            totalItems = sum(totalItems, parametros?.valorTaxaEntrega)
            setFrete(parametros?.valorTaxaEntrega)
        }
        setTotal(totalItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidoItens])

    const backHistory = () => {
        history.goBack()
    }


    useEffect(() => {
        if (updatePayment && currentOrder.pagamento) updatePayment(...Object.values(currentOrder.pagamento))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder.pagamento])

    return (
        <>
            <PageContainer>
                <AppBar>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={backHistory}/>
                    <span>Meu Pedido</span>
                    <div/>
                </AppBar>
                <ContainerItens>
                    <HeaderItens>
                        <span>ITENS</span>
                        <span>PREÇO</span>
                    </HeaderItens>

                    {pedidoItens.map((v: PedidoItem, index: number) =>
                        <Item key={index}>
                            <span>{`${v.amount} x ${v.product.name}`}</span>
                            <ItemPreco lighter valor={v.total}/>
                        </Item>
                    )}

                    <AdicionarItensButton onClick={backHistory} color={parametros?.idWebTemplate?.cor}>Adicionar mais itens</AdicionarItensButton>

                    <Total>
                        <span>TOTAL</span>
                        <ValorTotal lighter valor={total}/>
                    </Total>

                </ContainerItens>

                <Formik
                    initialValues={{
                        tipoEntrega: '',
                        pagamento: {
                            meioPagamento: '',
                            valor: 0,
                        },
                        nomeCliente: '',
                        telefone: '',
                        endereco: null,
                        observacoes: null,
                        novoEndereco: {
                            cep: '',
                            logradouro: '',
                            numero: '',
                            complemento: '',
                            bairro: '',
                            uf: '',
                            cidade: '',
                        }
                    }}
                    validationSchema={formSchema}
                    validateOnMount={true}
                    onSubmit={(values) => {
                        const {novoEndereco, ...v} = values

                        changePaymentOrder(v)
                        const data: Order = {...currentOrder, ...v}
                        handleOrder(data, parametros, frete, total)
                    }}
                >
                    {({values, handleSubmit, setFieldValue, isValid}: any) => {

                        updatePayment = (paymentMethod, value) => {
                            setFieldValue('pagamento.meioPagamento', paymentMethod)
                            setFieldValue('pagamento.valor', value)
                        }

                        const isValidManual = () => {
                            if (values.tipoEntrega === 'entrega') {
                                if (Object.values(values.novoEndereco).some(v => v === '')) return false
                            }
                            return true
                        }

                        const buscaCep = async (cep: string) => {
                            if (cep.length !== 10) return
                            try {
                                cep = cep.replace('-', '')
                                cep = cep.replace('.', '')
                                const result = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                                if (result.data.erro) return

                                const {logradouro, complemento, bairro, localidade: cidade, uf} = result.data
                                setFieldValue('novoEndereco', {
                                    logradouro,
                                    complemento,
                                    bairro,
                                    cidade,
                                    uf,
                                    numero: values.novoEndereco.numero,
                                })
                            } catch (e) {
                            }
                        }

                        const saveAddress = async () => {
                            const {novoEndereco} = values
                            const result = await addAdress(novoEndereco)
                            setFieldValue('novoEndereco', {
                                logradouro: '',
                                complemento: '',
                                bairro: '',
                                cidade: '',
                                uf: '',
                                numero: '',
                            })
                            await fetchAddress()
                            setFieldValue('endereco', result)
                            setIsEditingAddress(false)
                        }

                        return <>
                            <Observation>Escolha uma opção {values.tipoEntrega === '' ? <Badget>OBRIGATÓRIO</Badget> :
                                <EtapaConcluida/>}</Observation>
                            <ItemDescricao onClick={() => setFieldValue("tipoEntrega", "delivery")}>
                                <ColumnComponent>
                                    <ItemDescricaoTitle>Entrega {parametros?.taxaEntrega ?
                                        <span style={parametros?.entregaGratis && parametros?.entregaGratisAcimaDe <= total ? {textDecoration: 'line-through'} : null}>
                                            ` - ${parametros?.valorTaxaEntrega}`</span> : null}</ItemDescricaoTitle>
                                    <TextSmall>(sujeito a disponibilidade e taxas)</TextSmall>
                                </ColumnComponent>
                                {
                                    (parametros?.deliveryAtivo && (!parametros.exigePedidoMinDelivery || parametros.valorMinimoDelivery <= total)) ?
                                    <Radio name={'tipoEntrega'} value={'delivery'} checked={values.tipoEntrega === "delivery"}
                                           onChange={() => setFieldValue("tipoEntrega", "delivery")}/>
                                           : null
                                }
                            </ItemDescricao>
                            <ItemDescricao onClick={() => setFieldValue("tipoEntrega", "retirada")}>
                                <ColumnComponent>
                                    <ItemDescricaoTitle>Retirada no local</ItemDescricaoTitle>
                                    <TextSmall>(sujeito a disponibilidade)</TextSmall>
                                </ColumnComponent>
                                <Radio name={'tipoEntrega'} value={'retirada'} checked={values.tipoEntrega === "retirada"}
                                       onChange={() => {
                                           setFieldValue("tipoEntrega", "retirada")
                                           removeAddress()
                                       }}/>
                            </ItemDescricao>

                            <Observation>Como você vai pagar {values.pagamento.meioPagamento === '' ? <Badget>OBRIGATÓRIO</Badget> :
                                <EtapaConcluida/>}</Observation>
                            {
                                values.pagamento.meioPagamento === '' ? <AdicionarPagamentoButton onClick={openModalPagamento} color={parametros?.idWebTemplate?.cor}>
                                        <><FontAwesomeIcon icon={faDollarSign} style={{marginRight: '1rem'}}/>Selecione a forma de pagamento</>
                                    </AdicionarPagamentoButton>
                                    : <PagamentoSelecionado meioPagamento={values.pagamento.meioPagamento} valor={values.pagamento.valor}
                                                            onClick={() => {
                                                                openModalPagamento()
                                                                pagamentoEditData(values.pagamento)
                                                            }}/>
                            }

                            <Observation>Qual o seu nome? {values.nomeCliente === '' ? <Badget>OBRIGATÓRIO</Badget> : <EtapaConcluida/>}</Observation>
                            <Padding>
                                <Input value={values.nomeCliente} name={'nomeCliente'}
                                       onChange={(e: any) => setFieldValue('nomeCliente', e.target.value)}/>
                            </Padding>

                            {
                                values.tipoEntrega === 'delivery' ?
                                    <>
                                        <Observation>Qual seu endereço? {!values.endereco ?
                                            <Badget>OBRIGATÓRIO</Badget> : <EtapaConcluida/>}</Observation>
                                        {isEditingAddress ? <>
                                            <EnderecoFormTop>
                                                <InputComMascara label={'Digite o CEP'} mask={'99.999-999'} value={values.novoEndereco.cep}
                                                                 onChange={(e: any) => {
                                                                     setFieldValue('novoEndereco.cep', e.target.value)
                                                                     buscaCep(e.target.value)
                                                                 }}/>
                                            </EnderecoFormTop>
                                            <EnderecoFormMiddle>
                                                <InputComLabel label={'Logradouro (Rua, avenida, etc.)'} value={values.novoEndereco.logradouro}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.logradouro', e.target.value)}/>
                                                <InputComLabel label={'Bairro'} value={values.novoEndereco.bairro}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.bairro', e.target.value)}/>
                                            </EnderecoFormMiddle>
                                            <EnderecoFormMiddle>
                                                <InputComLabel label={'Cidade'} value={values.novoEndereco.cidade}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.cidade', e.target.value)}/>
                                                <InputComLabel label={'UF'} value={values.novoEndereco.uf}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.uf', e.target.value)}/>
                                            </EnderecoFormMiddle>
                                            <EnderecoFormMiddle>
                                                <InputComLabel label={'Complemento'} value={values.novoEndereco.complemento}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.complemento', e.target.value)}/>
                                                <InputComLabel label={'Número'} value={values.novoEndereco.numero}
                                                               onChange={(e: any) => setFieldValue('novoEndereco.numero', e.target.value)}/>
                                            </EnderecoFormMiddle>
                                        </> : <EnderecosSalvosContainer>
                                            {addressSaved.map((address: Endereco) => <AddressRepresentation name={'endereco'} value={address.id}
                                                                                                            checked={values.endereco === address.id}
                                                                                                            key={address.id} address={address}
                                                                                                            deletar={() => {
                                                                                                                deleteAddress(address.id)
                                                                                                                fetchAddress()
                                                                                                            }}
                                                                                                            onClick={() => setFieldValue('endereco', address.id)}
                                                                                                            onChange={() => setFieldValue('endereco', address.id)}/>)}
                                        </EnderecosSalvosContainer>
                                        }

                                        {
                                            isEditingAddress ?
                                                <><ConfirmarEnderecoButton disabled={!isValidManual()} onClick={saveAddress} color={parametros?.idWebTemplate?.cor}>Confirmar
                                                    Endereço</ConfirmarEnderecoButton>
                                                    <NovoEnderecoButton onClick={() => setIsEditingAddress(false)} color={parametros?.idWebTemplate?.cor}>Cancelar</NovoEnderecoButton></>
                                                : <NovoEnderecoButton onClick={() => setIsEditingAddress(true)} color={parametros?.idWebTemplate?.cor}>Novo Endereco</NovoEnderecoButton>
                                        }

                                    </> : null
                            }

                            <Observation>
                                <div>
                                    <div>Seu telefone</div>
                                    <span style={{fontSize: '1.2rem', fontWeight: 500}}>(caso a gente precise falar com você)</span></div>
                                {values.telefone === '' ? <Badget>OBRIGATÓRIO</Badget> : <EtapaConcluida/>}</Observation>
                            <Padding>
                                <InputComMascara mask={'(99) 99999-9999'} value={values.telefone} name={'telefone'}
                                                 onChange={(e: any) => setFieldValue('telefone', e.target.value)}/>
                            </Padding>
                            {
                                parametros?.obsPedido ?
                                    <>
                                        <Observation>Observações</Observation>
                                        <Padding>
                                            <InputComLabel label={'Observações'} value={values.observacoes}
                                                           onChange={(e: any) => setFieldValue('observacoes', e.target.value)}/>
                                        </Padding>
                                    </> : null
                            }

                            <Footer>
                                <FinalizarButton onClick={handleSubmit} type={'submit'} disabled={!isValid} color={parametros?.idWebTemplate?.cor}>
                                    <span>Enviar pedido pelo Whatsapp</span>
                                    <ProductPriceComCifrao valor={total}/>
                                </FinalizarButton>
                            </Footer>
                        </>
                    }}
                </Formik>
            </PageContainer>
            <ModalPagamentos/>
        </>
    );
};

const PageContainer = styled(MaxWidth)`
padding-bottom: 10rem;
`

const ContainerItens = styled(Padding)`
${Column};
`

const HeaderItens = styled.h2`
${Row};
font-weight: 600;
margin-bottom: 2rem;
`

const Total = styled(HeaderItens)`
margin: 2rem 0;
`

const Item = styled.div`
${Row};
font-size: 1.4rem;
font-weight: 300;
margin: 1rem 0;
`

const ItemPreco = styled(ProductPriceComCifrao)`
${Row};
justify-content: stretch;

span {
margin-right: .5rem; 
}
`

const ValorTotal = styled(ProductPriceComCifrao)`
font-weight: 600;
`

const Footer = styled(CardFooter)`
padding: 1rem 1.5rem;
border-top: .3rem solid ${bodyColorDark};
background-color: #FFF;
`

const FinalizarButton: any = styled(PrimaryButton)`
padding: 1rem .8rem;
${Row};
width: 100%;
`

const AdicionarItensButton = styled(TransparentButton)`
padding: 1.3rem .8rem;
width: 100%;
font-size: 1.6rem;
`

const AdicionarPagamentoButton = styled(AdicionarItensButton)`
padding: 1.3rem 2rem;
margin: 2rem;
width: calc(100% - 4rem);
display: block;
`

const Badget = styled.div`
background-color: ${colorDark};
color: #FFF;
padding: .8rem 1rem;
border-radius: 2rem;
font-size: 1.2rem;
`

const ItemDescricao = styled(Padding)`
${Row};
cursor: pointer;
`

const ItemDescricaoTitle = styled(TextBig1)`
margin-bottom: .2rem;
`

const EtapaConcluida = styled.div.attrs(() => ({
    children: <FontAwesomeIcon icon={faCheck} color={'#fff'}/>
}))`
width: 3rem;
height: 3rem;
border-radius: 50%;
background-color: ${colorGreen};
${Row};
justify-content: center;
`

const EnderecoFormTop = styled.div`
padding: 2rem 2rem 1rem;
`

const EnderecoFormMiddle = styled.div`
padding: 1rem 2rem;
display: grid;
grid-template-columns: 2fr 1fr;
grid-column-gap: 2rem;
`

const NovoEnderecoButton = styled(TransparentButton)`
margin: 2rem;
width: calc(100% - 4rem);
padding: 1rem;
`

const ConfirmarEnderecoButton = styled(PrimaryButton)`
margin: 2rem 2rem 0;
width: calc(100% - 4rem);
padding: 1rem;
`

const EnderecosSalvosContainer = styled(Padding)`
padding-bottom: 0;
`

const PagamentoSelecionadoContainer = styled.div`
${Row};
align-items: flex-start;
cursor: pointer;
padding: 2rem;
margin: 2rem;
box-shadow: 0 0 .5rem 0 rgba(0,0,0,0.25);
border-radius: .4rem;
color: ${colorDarkMedium};
`

const Left = styled.div`
display: flex;
`

const Description = styled.div`
${Column};
align-items: flex-start;
margin-left: 2rem;
`

const Text = styled.span`
font-size: 1.4rem;
margin-top: .5rem;
`

const PagamentoSelecionado = ({meioPagamento, valor, onClick}) => {

    let icon

    switch (meioPagamento) {
        case 'dinheiro':
            icon = faMoneyBillAlt
            break
        case 'cartao':
            icon = faCreditCard
            break
    }

    return (
        <PagamentoSelecionadoContainer onClick={onClick}>
            <Left>
                <FontAwesomeIcon icon={icon} size={'2x'} style={{alignSelf: 'center'}}/>
                <Description>
                    <SubTitle>Pagar na Entrega</SubTitle>
                    <Text>{meioPagamento}</Text>
                    {meioPagamento === 'dinheiro' ? <Text>Troco para: R$ {valor}</Text> : null}
                </Description>
            </Left>
            <FontAwesomeIcon icon={faEdit} size={'2x'} style={{width: '1.8rem', alignSelf: 'flex-start'}}/>
        </PagamentoSelecionadoContainer>
    )
}

export default PagamentoPage;
