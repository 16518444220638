import React, {createContext, useState} from 'react';
import {Product} from "../models/product";

export interface PedidoItem {
    product: Product,
    amount: number,
    total: number,
}

export interface Endereco {
    id: number;
    cep: string;
    cidade: string;
    uf: string;
    logradouro: string;
    bairro: string;
    complemento: string;
    numero: string;
}

export interface Order {
    pedidoItens: PedidoItem[]
    tipoEntrega: string
    nomeCliente: string
    telefone: string
    endereco?: number
    observacoes?: string
    pagamento?: Pagamento
}

export interface Pagamento {
    meioPagamento: string
    valor: number
}

// @ts-ignore
export const OrderContext = createContext()

const OrderContextProvider = ({children}: any) => {

    const [order, setOrder]: [Order, Function] = useState({
        pedidoItens: [],
        tipoEntrega: '',
        nomeCliente: '',
        telefone: '',
    })

    const addProduct = (item: PedidoItem) => {
        setOrder((prev: Order) => ({
            ...prev, pedidoItens: [...prev.pedidoItens, item]
        }))
    }

    const changePaymentOrder = (order: Order) => {
        setOrder((prev: Order) => ({
            ...prev, ...order
        }))
    }

    const removeAddress  = () => {
        setOrder((prev: Order) => ({
            ...prev, endereco: null,
        }))
    }

    const setMeioPagamento = (pagamento: Pagamento) => {
        setOrder((prev: Order) => ({
            ...prev, pagamento
        }))
    }

    return (
        <OrderContext.Provider value={{order, addProduct, changePaymentOrder, removeAddress, setMeioPagamento}}>
            {children}
        </OrderContext.Provider>
    );
};

export default OrderContextProvider;
