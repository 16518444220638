import styled, {css} from "styled-components";

export const Row = css`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Column = css`
display: flex;
flex-direction: column;
`

export const RowComponent = styled.div`
${Row};
`

export const ColumnComponent = styled.div`
${Column};
`
