import styled from "styled-components";
import {Row} from "../styles/flex";
import {darkTextColor} from "../styles/typography";
import {bodyColorDark} from "../styles/variables";

export const AppBar = styled.div`
${Row};
padding: 1.5rem 2rem;
margin-bottom: 1rem;
font-size: 2rem;
${darkTextColor};
user-select: none;
border-bottom: .1rem solid ${bodyColorDark};
`
