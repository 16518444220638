import styled from "styled-components";
import {darkTextColor} from "./typography";
import {Column} from "./flex";

export const ProductInfoContent = styled.div`
${Column};
`
export const ProductName = styled.h2`
font-weight: 400;
font-size: 1.6rem;
text-transform: uppercase;
`

export const ProductDescription = styled.span`
font-size: 1.4rem;
${darkTextColor};
margin: .5rem 0 1rem;
`

export const ProductPrice = styled.h2`

`
