import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet-async";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {dominioName} from "./NavigationHelper";
import {Parametros} from "../models/Parametros";
import {useAddToHomescreenPrompt} from "../hooks/useAddToHomescreenPrompt";

const getIconUrl = (url: string, size: number) => {
    if (url) return url.replace('original', size.toString())
    return `https://toqweb-images.s3.amazonaws.com/logo/icon-${size}x${size}.png`
}

const getManifest = (parametros: Parametros) => {
    const myDynamicManifest = {
        "name": parametros.nomeLoja,
        "short_name": parametros.nomeLoja,
        "theme_color": parametros.idWebTemplate.cor,
        "background_color": parametros.idWebTemplate.cor,
        "display": "fullscreen",
        "orientation": "portrait",
        "Scope": "/",
        "start_url": `https://superzapp.toqweb.com.br/${dominioName}`,
        "icons": [
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 72),
                "sizes": "72x72",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 96),
                "sizes": "96x96",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 128),
                "sizes": "128x128",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 144),
                "sizes": "144x144",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 152),
                "sizes": "152x152",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 192),
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 384),
                "sizes": "384x384",
                "type": "image/png"
            },
            {
                "src": getIconUrl(parametros?.idWebTemplate?.logo, 512),
                "sizes": "512x512",
                "type": "image/png"
            }
        ],
        "splash_pages": null
    }
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    return manifestURL
}

const HtmlHeaders = () => {

    const {parametros}: any = useContext(ApiDataContext)

    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);

    const hide = () => setVisibleState(false);

    useEffect(() => {
        console.log(parametros, prompt)

        let timer
        if(parametros && prompt) {
            timer = setTimeout(() => {
                setVisibleState(true)
            }, 2000)
        }

        return () => {
            if(timer) {
                clearTimeout(timer)
            }
        }
    }, [parametros, prompt])

    return (
        <>
            <Helmet>
                {parametros &&
                <>
                    <title>{parametros.nomeLoja}</title>
                    <link rel={'manifest'} href={getManifest(parametros)}/>
                </>
                }
            </Helmet>
            {
                isVisible ?<div onClick={hide} style={{backgroundColor: 'red'}}>
                    <button onClick={hide}>Close</button>
                    Hello! Wanna add to homescreen?
                    <button onClick={promptToInstall}>Add to homescreen</button>
                </div> : null
            }
        </>
    );
};

export default HtmlHeaders;
