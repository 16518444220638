import styled from "styled-components";
import MaxWidth from "../layouts/MaxWidth";
import {Row} from "../styles/flex";

export const CardFooter: any = styled(MaxWidth)`
${Row};
position: fixed;
bottom: 0;
left: 50%;
transform: translateX(-50%);
width: 100%;
`
