import React, {useContext, useState} from 'react';
import Topbar, {TopTopbar} from "../layouts/Topbar";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {Title} from "../styles/typography";
import MaxWidth from "../layouts/MaxWidth";
import {bodyColorDark, colorDarkLighter, colorDarkMedium} from "../styles/variables";
import {Row} from "../styles/flex";
import {ProductDescription, ProductInfoContent, ProductName, ProductPrice} from "../styles/productStyles";
import {OrderContext, PedidoItem} from "../contexts/OrderContext";
import ProductPriceComCifrao from "../components/ProductPriceComCifrao";
import {CardFooter} from "../components/Footer";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {Product} from "../models/product";
import InfiniteScroll from "react-infinite-scroll-component";
import {AMOUNT_OF_REGISTER_ON_PAGINATION} from "../util/constants";
import {dominioName} from "../config/NavigationHelper";
import Loader from "../layouts/Loader";
import {Categoria} from "../models/Categoria";
import {DrawerContext} from "../contexts/DrawerContext";
import {useScrollYPosition} from 'react-use-scroll-position'
import {Input} from "../components/Form";
import {useDebouncedCallback} from "use-debounce";

const MenuPage = ({history}: any) => {

    const {openDrawer}: any = useContext(DrawerContext)

    const {order}: any = useContext(OrderContext)
    const {pedidoItens} = order

    const {products, listProdutos, productPaginationHelper, categoriaSelecionada, categories, setCategoriaSelecionada, parametros}: any = useContext(ApiDataContext)

    const handleCheckout = () => {
        history.push(`/${dominioName}/pagamento`)
    }

    const isScrolled = useScrollYPosition() > 200

    const [textPesquisa, setTextPesquisa] = useState<String>('')


    const [debouncedCallback] = useDebouncedCallback((value: string) => {
        listProdutos(true, value)
    }, 1000);


    const handleTextPesquisa = (value: string) => {
        setTextPesquisa(value)
        debouncedCallback(value)
    }

    return categoriaSelecionada == null ? null : (
        <>
            <Topbar/>
            <MaxWidth>
                <StyckContainer isScrolled={isScrolled}>
                    {
                        isScrolled ? <TopTopbarStycked color={parametros?.idWebTemplate?.cor}>
                            <FontAwesomeIcon icon={faBars} size={'2x'} onClick={openDrawer}/>
                            <TopbarTitle>{parametros.nomeLoja}</TopbarTitle>
                        </TopTopbarStycked> : null
                    }
                    <CategoryContainer>
                        {
                            categories.map((categoria: Categoria) => <Category key={categoria.id} onClick={() => setCategoriaSelecionada(categoria)}
                                                                               selected={categoriaSelecionada.id === categoria.id}>{categoria.name}</Category>)
                        }
                    </CategoryContainer>
                </StyckContainer>
                <Input value={textPesquisa} placeholder={'Pesquisar por...'} onChange={(e: any) => handleTextPesquisa(e.target.value)}/>
                <Hr/>


                <ListItens>
                    <InfiniteScroll
                        dataLength={productPaginationHelper.currentPage * AMOUNT_OF_REGISTER_ON_PAGINATION}
                        next={listProdutos}
                        hasMore={productPaginationHelper.totalPages >= productPaginationHelper.currentPage}
                        loader={<Loader/>}
                    >
                        {products.map((product: Product) => (
                            <ListItem key={product.id} onClick={() => history.push(`/${dominioName}/menu/${product.id}`)}>
                                <ProductInfoContent>
                                    <ProductName>{product.name}</ProductName>
                                    <ProductDescription>{product.description}</ProductDescription>
                                    <ProductPriceComCifrao valor={product.price}/>
                                </ProductInfoContent>
                                <ListItemImage src={product.image}/>
                            </ListItem>
                        ))}
                    </InfiniteScroll>


                </ListItens>
                <CartFooter show={pedidoItens.length} onClick={handleCheckout} color={parametros?.idWebTemplate?.cor}>
                    <CartItens>
                        <FontAwesomeIcon icon={faShoppingCart}/>
                        <QtdItensCart>{pedidoItens.length}</QtdItensCart>
                    </CartItens>
                    <span>Meu Pedido</span>
                    <ProductPriceComCifrao valor={pedidoItens.map((v: PedidoItem) => v.total).reduce((acc: number, cur: number) => acc + cur, 0)}/>
                </CartFooter>
            </MaxWidth>
        </>
    );
};


const StyckContainer: any = styled.div`
position: sticky;
top: 0;
background-color: #fff;
${({isScrolled}: any) => isScrolled ? ({boxShadow: '0 .2rem .5rem 0 rgba(0,0,0,0.25)'}) : ({})};
`

const TopTopbarStycked = styled(TopTopbar)`
color: #fff;
margin-bottom: 2rem;
`

const TopbarTitle = styled(Title)`
white-space: nowrap;
`

const CategoryContainer = styled.div`
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;
padding: 0 2rem 2rem;
`

const Category: any = styled.div`
padding: .8rem 2rem;
margin-right: 2rem;
font-size: 1.4rem;
border-radius: .4rem;
display: inline-block;
cursor: pointer;
border: .1rem solid transparent;
${({selected}: any) => selected ? ({
    color: colorDarkMedium,
    backgroundColor: '#fff',
    border: `.1rem solid ${colorDarkMedium}`,
}) : ({
    color: '#fff',
    backgroundColor: colorDarkMedium,
})};
`

const Hr = styled.div`
height: .1rem;
background-color: ${colorDarkLighter};
`

const ListItens = styled.ul`
margin-bottom: 8rem;
`

const ListItem = styled.li`
${Row};
padding: 2rem;
&:hover {
background-color: ${bodyColorDark}; 
cursor: pointer;
}
`

const ListItemImage = styled.img`
width: 10rem;
margin-left: 1rem;
`

const CartFooter: any = styled(CardFooter)`
cursor: pointer;
padding: 1.5rem 2rem;
background-color: ${({color}) => color};
color: #fff;
font-size: 1.6rem;
box-shadow: 0 -.2rem .5rem 0 rgba(0,0,0,0.25);

${(props: any) => !props.show && ({
    maxHeight: '0',
    padding: '0',
    overflow: 'hidden',
})};

span {
font-weight: 600;
margin-left: 2rem;
}

${ProductPrice} {
font-size: 1.8rem;
}
`

const CartItens = styled.div`
display: flex;
position: relative;
`

const QtdItensCart = styled.div`
position: absolute;
top: -.8rem;
right: -1.4rem;
${Row};
justify-content: center;
background-color: #FFF;
color: #000;
font-size: 1.2rem;
border-radius: 50%;
width: 1.5rem;
`

export default MenuPage;
