import React, {createContext, useState} from 'react';

// @ts-ignore
export const DrawerContext = createContext()

const DrawerContextProvider = ({children}: any) => {

    const [isOpen, setIsOpen] = useState(false)

    const openDrawer = () => setIsOpen(true)

    const closeDrawer = () => setIsOpen(false)

    return (
        <DrawerContext.Provider value={{isOpen, openDrawer, closeDrawer}}>
            {children}
        </DrawerContext.Provider>
    );
};

export default DrawerContextProvider;
