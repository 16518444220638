import React from 'react';

const NotFound = () => {
    return (
        <h1>
           Page Not Found
        </h1>
    );
};

export default NotFound;
