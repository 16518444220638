import React, {createContext, useEffect, useState} from "react";
import axios from "axios";
import {setToken} from "../config/network";

export const LoginContext = createContext(null)

const LoginContextProvider = ({children}: any) => {

    const [token, setClientToken] = useState(null)

    const login = async () => {
        const pathName = window.location.href

        //TODO trocar pela url real depois
        let nomeLoja
        console.log(process.env.NODE_ENV)
        if(process.env.NODE_ENV === "development") {
            nomeLoja = pathName.replace('http://localhost:3000/', '')
        } else {
            console.log('entrei')
            nomeLoja = pathName.replace('https://superzapp.toqweb.com.br/', '')
            nomeLoja = pathName.replace('https://www.superzapp.toqweb.com.br/', '')
        }

        nomeLoja = nomeLoja.replace(/\/.+$/, '')
        let codDivulgador: any = pathName.match(/\?\w+$/)
        if(codDivulgador) {
            codDivulgador = codDivulgador[0].replace('?', '')
        }

        const {data} = await axios.post('https://api.toqweb.com.br:2004/auth/LoginService/LoginSuperZapp', {
            UrlLoja: nomeLoja,
            CodDivulgador: codDivulgador,
        })
        setToken(`Bearer ${data.value}`)
        setClientToken(data.value)
    }

    useEffect(() => {
        login()
    }, [])

    return (
        <LoginContext.Provider value={{
            token,
        }}>
            {token ? children : null}
        </LoginContext.Provider>
    )
}

export default LoginContextProvider
