import React, {useContext} from 'react';
import styled from "styled-components";
import {PrimaryButton} from "./Button";
import {Row} from "../styles/flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {darkTextColor} from "../styles/typography";
import {ApiDataContext} from "../contexts/ApiDataContext";

const CountButton = ({count, handlePlus, handleMinus}: any) => {

    const {parametros} = useContext(ApiDataContext)

    return (
        <CountButtonStyle>
            <ClickButton onClick={handleMinus} color={parametros?.idWebTemplate?.cor}>
                <FontAwesomeIcon icon={faMinus} />
            </ClickButton>
            <Count>{count}</Count>
            <ClickButton onClick={handlePlus} color={parametros?.idWebTemplate?.cor}>
                <FontAwesomeIcon icon={faPlus} />
            </ClickButton>
        </CountButtonStyle>
    );
};

const CountButtonStyle = styled.div`
${Row}
`

const ClickButton = styled(PrimaryButton)`
padding: 1rem .8rem;
`

const Count = styled.span`
font-size: 1.8rem;
font-weight: bold;
${darkTextColor};
margin: 0 1rem;
`

export default CountButton;
