import {colorDark} from "./variables";
import styled from "styled-components";

export const darkTextColor = `color: ${colorDark}`
export const textColorWhite = `color: #FFF`

export const TextSmall = styled.span`
font-size: 1.2rem; 
font-weight: 300;
`

export const TextBig1 = styled.span`
font-size: 1.6rem; 
font-weight: 400;
`


export const Title = styled.h1`

`

export const SubTitle = styled.h2`

`
