import React from 'react';
import styled from "styled-components";
import ReactInputMask from "react-input-mask";
import {bodyColorDark, secundaryColor} from "../styles/variables";

const InputComMascara = ({label, ...props}: any) => {

    return (
        <div>
            <Label>{label}</Label>
            <InputMaskEstitilizado {...props} maskPlaceholder={''} />
        </div>
    );
};

export default InputComMascara;


const Label = styled.label`
font-size: 1.4rem;
font-weight: 400;
margin-bottom: .2rem;
display: block;
`

const InputMaskEstitilizado = styled(ReactInputMask)`
width: 100%;
padding: 1rem;
border-radius: .2rem;
border: .1rem solid ${bodyColorDark};

&:focus {
border: .1rem solid ${secundaryColor};
}
`
