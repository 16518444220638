import styled from "styled-components";
import {bodyColorDark} from "../styles/variables";
import {Row} from "../styles/flex";


export const Observation = styled.div`
${Row};
padding: 1.5rem 2rem;
background-color: ${bodyColorDark};
font-weight: 600;
font-size: 1.5rem;
`

