export const toNumber = (val: any) =>  {
    const v = val === undefined ? 0 : val === null ? 0 : typeof val === 'number' ? Number(val.toFixed(2)) : Number.parseFloat(val.toString().replace(',', '.'))
    return isNaN(v) || !isFinite(v) ? 0 : v
}

export const sum = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a + b).toFixed(2))
}

export const sub = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a - b).toFixed(2))
}

export const mul = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a * b).toFixed(2))
}

export const div = (a: any, b: any) => {
    a = toNumber(a)
    b = toNumber(b)
    return toNumber((a / b).toFixed(2))
}
