import {all} from 'redux-saga/effects'


function* apiDataSaga(): any {
    yield all([

    ])
}

export default apiDataSaga
