import {useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import axios from 'axios'

export let dominioName: string

const NavigationHelper = (props: any) => {

    const {pathname} = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)

        dominioName = pathname.substring(1).replace(/\/.+/, '')
        axios.interceptors.request.use(function (config) {
            // config.headers.dominio = dominioName;
            return config;
        });


    }, [pathname])

    return null
};

export default NavigationHelper;
