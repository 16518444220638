// export const primaryColor = 'rgb(84, 156, 206)'
// export const primaryColorLight = 'rgb(106,170,215)'
export const secundaryColor = 'rgb(127, 143, 159)'
export const secundaryColorLight = 'rgba(127, 143, 159, .6)'
export const bodyColor = 'rgb(255, 255, 255)'
export const bodyColorDark = 'rgb(234,236,238)'
export const colorDark = 'rgb(72, 84, 96)'
export const colorDarkMedium = 'rgb(91,102,113)'
export const colorDarkLighter = 'rgba(72, 84, 96, .2)'
export const colorGreen = 'rgb(39, 174, 96)'

