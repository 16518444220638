import Dexie from 'dexie'
import {Address} from "cluster";

const db = new Dexie('superzapp_db')

db.version(1).stores({
    address: '++id,cep,cidade,uf,logradouro,bairro,complemento,numero'
})

export const addAdress = async (address: Address) => {
    try {
        // @ts-ignore
        return db['address'].add(address)
    } catch (e) {
        console.log(e)
    }
}

export const listAddress = async () => {
    // @ts-ignore
    return db['address'].toArray();
}

export const getAddress = async (id: number) => {
    // @ts-ignore
    return db['address'].get(id);
}

export const deleteAddress = async (id: number) => {
    // @ts-ignore
    return db['address'].delete(id);
}

export default db
