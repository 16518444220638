import styled from "styled-components";
import {secundaryColor} from "../styles/variables";
import {textColorWhite} from "../styles/typography";
import {clarearCor} from "../util/color_util";

const FlatButton = styled.button`
font-size: 1.5rem;
padding: .5rem 1rem;
box-shadow: 0 .4rem .5rem 0 rgba(0,0,0,0.25);
align-self: flex-end;
user-select: none;
`

export const FlatGreenButton = styled(FlatButton)` 
background-color: ${({color}) => color};
${textColorWhite};
`

const Button = styled.button.attrs(({type}) => ({
    type,
}))`
font-size: 1.5rem;
padding: .5rem 1rem;
user-select: none;
border-radius: .3rem;
`

export const PrimaryButton = styled(Button)`
background-color: ${({color}) => color};
color: #FFF;
border: .1rem solid transparent;
&:active {
background-color: ${({color}) => clarearCor(color)};
}
&:disabled {
  border: .1rem solid #999999;
  background-color: ${secundaryColor};
  color: #fff;
  pointer-events: none; 
}
`

export const TransparentButton = styled(Button)`
background-color: #FFF;
color: ${({color}) => color};
border: .1rem solid ${({color}) => color};
&:active {
background-color: ${({color}) => clarearCor(color)};
color: #FFF;
}
`
