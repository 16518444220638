const INITIAL_STATE = {
    pedidoItems: [],
    pagamentos: [],
    erro: null,
    qtdParcela: 0,
    caixasPdv: [],
    redesAdquirentes: [],
    bandeiras: [],
    caixaSelecionado: null,
}

const apiData = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        default:
            return state
    }
}

export default apiData