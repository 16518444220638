import React, {useContext} from 'react';
import styled from "styled-components";
import {Column} from "../styles/flex";
import ReactLoading from 'react-loading';
import {ApiDataContext} from "../contexts/ApiDataContext";

const Loader = () => {

    const {parametros} = useContext(ApiDataContext)

    return (
        <LoaderContainer>
            <ReactLoading type={'spinningBubbles'} color={parametros?.idWebTemplate?.cor} height={100} width={100} />
        </LoaderContainer>
    );
};

export default Loader;

const LoaderContainer = styled.div`
width: 100vw;
height: 100vh;
overflow: hidden;
${Column};
justify-content: center;
align-items: center;
position: fixed;
left: 0;
top: 0;
background-color: rgba(255, 255, 255, .4);
`

