import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {Row} from "../styles/flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ProductDescription, ProductInfoContent, ProductName, ProductPrice} from "../styles/productStyles";
import {bodyColorDark} from "../styles/variables";
import {Padding} from "../styles/padding";
import CountButton from "../components/CountButton";
import {PrimaryButton} from "../components/Button";
import MaxWidth from "../layouts/MaxWidth";
import ProductPriceComCifrao from "../components/ProductPriceComCifrao";
import {OrderContext} from "../contexts/OrderContext";
import {AppBar} from "../components/AppBar";
import {CardFooter} from "../components/Footer";
import {Observation} from "../components/DivisionSubtitle";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {mul, sub, sum} from "../util/math_util";

const ProdutoPage = ({history, match}: any) => {

    const {id} = match.params

    const {addProduct}: any = useContext(OrderContext)

    const {getProduct, parametros}: any = useContext(ApiDataContext)


    const backHistory = () => {
        history.goBack()
    }

    const [orderDetail, setOrderDetail]: any = useState({
        amount: 1,
        total: 0,
        product: null,
    })

    useEffect(() => {
        const produtoPesquisado = getProduct(Number(id))
        setOrderDetail((prev: any) => ({...prev, product: produtoPesquisado, total: produtoPesquisado.price}))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handlePlus = () => {
        setOrderDetail((prev: any) => {
            const newAmount = sum(prev.amount, 1)
            return {
                ...prev,
                amount: newAmount,
                total: mul(prev.product.price, newAmount),
            }
        })
    }

    const handleMinus = () => {
        if (orderDetail.amount === 1) return
        setOrderDetail((prev: any) => {
            const newAmount = sub(prev.amount, 1)
            return {
                ...prev,
                amount: newAmount,
                total: mul(prev.product.price, newAmount),
            }
        })
    }

    const handleAddProduct = () => {
        addProduct(orderDetail)
        backHistory()
    }
    return orderDetail.product == null ? null : (
        <MaxWidth>
            <AppBar>
                <FontAwesomeIcon icon={faArrowLeft} onClick={backHistory}/>
                <span>DETALHE DO PRODUTO</span>
                <div/>
            </AppBar>
            <ProductPhotoContainer>
                <ProductPhoto src={orderDetail.product.image}/>
            </ProductPhotoContainer>
            <ProductInfoContentStyled>
                <ProductName>{orderDetail.product.name}</ProductName>
                <ProductDescription>{orderDetail.product.description}</ProductDescription>
                <ProductPriceComCifrao valor={orderDetail.product.price} />
            </ProductInfoContentStyled>
            <Observation>Alguma observação?</Observation>
            <Padding>
                <TextArea/>
                <ObservationText>
                    Converse diretamente com o restaurante caso queira modificar algum ingrediente. Neste campo não são aceitas modificações que podem
                    gerar cobrança adicional.
                </ObservationText>
            </Padding>
            <Order>
                <CountButton handlePlus={handlePlus} handleMinus={handleMinus} count={orderDetail.amount}/>
                <AdicionarButton onClick={handleAddProduct} color={parametros?.idWebTemplate?.cor}>
                    <span>Adicionar</span>
                    <ProductPriceComCifrao valor={orderDetail.total} />
                </AdicionarButton>
            </Order>
        </MaxWidth>
    );

};


const ProductPhotoContainer = styled.div`
${Row};
justify-content: center;
`

const ProductPhoto = styled.img`
width: 18rem;
margin: 2rem;
`

const ProductInfoContentStyled = styled(ProductInfoContent)`
padding: 2rem;
`

const TextArea = styled.textarea.attrs(() => ({
    rows: '6',
}))`
width: 100%;
padding: 1rem;
`

const ObservationText = styled(ProductDescription)`
font-size: 1.2rem;
display: inline-block;
margin: 1rem 0 8rem;
`

const Order = styled(CardFooter)`
padding: 1rem 2rem;
border-top: .3rem solid ${bodyColorDark};
background-color: #FFF;
`

const AdicionarButton = styled(PrimaryButton)`
padding: 1rem .8rem;
${Row};
width: 18rem;
span {
font-size: 1.6rem;
}

${ProductPrice} {
font-size: 1.8rem;
}

`

export default ProdutoPage;
