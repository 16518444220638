import React, {createContext, useEffect, useState} from 'react';
import {Product} from "../models/product";
import {AMOUNT_OF_REGISTER_ON_PAGINATION, URL_API} from "../util/constants";
import _ from 'lodash'
import {Categoria} from "../models/Categoria";
import {Parametros} from "../models/Parametros";
import api from "../config/network";
import {toCamelCase} from "../util/transform_case";

export interface ProductPaginationHelper {
    productsLength: number;
    currentPage: number;
    totalPages: number;
}

export const ApiDataContext = createContext(null)

const ApiDataContextProvider = ({children}: any) => {

    const listProdutos = async (resetPagination = false, textPesquisa = '') => {

        let currentPage
        if (resetPagination) {
            currentPage = 1
        } else {
            currentPage = productPaginationHelper.currentPage + 1
        }

        const {data} = await api.get(`${URL_API}/app/produtos2?Pagina=${currentPage}&IdCategoria=${categoriaSelecionada.id}&Text='${textPesquisa}'`)

        const products = data.value.map((v: any) => ({
            id: v.Id,
            name: v.Nome,
            description: null,
            price: v.ValorVenda,
            image: v.Foto,
        }))

        if (resetPagination) {
            setProducts(products)
        } else {
            setProducts((prev: Product[]) => _.uniqBy([...prev, ...products], 'id'))
        }

        if (data.value.length) {
            const productsLength = data.value[0].QtdReg

            const totalPages = Math.ceil(productsLength / AMOUNT_OF_REGISTER_ON_PAGINATION)
            setProductPaginationHelper({
                productsLength,
                currentPage,
                totalPages,
            })
        } else {
            setProductPaginationHelper({
                productsLength: 0,
                currentPage: 1,
                totalPages: 0,
            })
        }
    }

    const listCategorias = async () => {
        if (categories.length) return

        const {data} = await api.get(`${URL_API}/app/categorias`)

        const categorias: Categoria[] = data.value.map((v: any) => ({
            id: v.Id,
            name: v.Nome,
        }))

        setCategories(categorias)
        if (categorias.length) {
            setCategoriaSelecionada(categorias[0])
        }
    }

    const loadParametrosEmpresa = async () => {
        if (categories.length > 1) return

        const {data} = await api.get(`${URL_API}/parametrosSuperZapp?$expand=IdWebTemplate`)
        const {value} = data
        if (!value.length) return
        const companyInfo: Parametros = toCamelCase(value[0])
        if(companyInfo) {
            setParametros(companyInfo)
        }
    }

    const [products, setProducts]: [Product[], Function] = useState([])
    const [categories, setCategories]: [Categoria[], Function] = useState([])

    const [categoriaSelecionada, setCategoriaSelecionada]: any = useState()
    const [parametros, setParametros]: [any, Function] = useState()


    const [productPaginationHelper, setProductPaginationHelper]: [ProductPaginationHelper, Function] = useState({
        productsLength: 0,
        currentPage: 0,
        totalPages: 1,
    })



    const getProduct = (id: number) => {
        const item = products.filter(product => product.id === id)
        if (item.length) return item[0]

        return null
    }

    useEffect(() => {
        listCategorias()
        loadParametrosEmpresa()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (categoriaSelecionada) listProdutos(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriaSelecionada])

    return (
        <ApiDataContext.Provider value={{
            products, getProduct, listProdutos, productPaginationHelper, categories,
            categoriaSelecionada, setCategoriaSelecionada, parametros
        }}>
            {children}
        </ApiDataContext.Provider>
    );
};

export default ApiDataContextProvider;
