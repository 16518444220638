import styled from "styled-components";
import {bodyColor, bodyColorDark, colorDark, secundaryColor} from "../styles/variables";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import React, {useContext} from 'react';
import {Row} from "../styles/flex";
import {renderToString} from "react-dom/server";
import {ApiDataContext} from "../contexts/ApiDataContext";

export const Radio = (props: any) => {

    const {parametros} = useContext(ApiDataContext)

    return (
        <RadioContainer>
            <RadioButton {...props} color={parametros?.idWebTemplate?.cor}/>
            <RadioButtonLabel/>
        </RadioContainer>
    );
};

const RadioContainer = styled.div`
position: relative;
width: 2.4rem;
height: 2.4rem;
`

const RadioButtonLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 50%;
background-color: #fff;
border: .1rem solid ${colorDark};

font-style: normal;
font-variant: normal;
text-rendering: auto;
-webkit-font-smoothing: antialiased;

${Row};
justify-content: center;
`

export const RadioButton = styled.input.attrs(() => ({
type: 'radio',
}))`
position: relative;
opacity: 0;
z-index: 1;
border-radius: 50%;
width: 100%;
height: 100%;

&:hover ~ ${RadioButtonLabel} {
background-color: ${bodyColorDark};

&::after {
content: '';
display: block;
border-radius: 50%;
width: 50%;
height: 50%;
background-color: ${bodyColor};
}
}

&:checked + ${RadioButtonLabel} {
background-color: ${({color}) => color};
border: .1rem solid ${({color}) => color};

&:after {
//font-family: "Font Awesome 5 Free"; 
//font-weight: 900;
content: url(data:image/svg+xml,${encodeURIComponent(renderToString(<FontAwesomeIcon icon={faCheck} color={'#fff'} size={'2x'}/>))});
display: inline-block;
background-color: transparent;
border-radius: 50%;
width: 60%;
height: 60%;
}
} 

`

export const Input: any = styled.input.attrs(({type = 'text'}: any) => ({
    type,
}))`
width: 100%;
padding: 1rem;
border-radius: .2rem;
border: .1rem solid ${bodyColorDark};

&:focus {
border: .1rem solid ${secundaryColor};
}
`

