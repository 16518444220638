import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import ModalContextProvider, {ModalContext} from "../contexts/ModalContext";
import {Column, Row} from "../styles/flex";
import {bodyColorDark, colorDark, colorDarkMedium, secundaryColor} from "../styles/variables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faMoneyBillAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {SubTitle, Title} from "../styles/typography";
import {CardFooter} from "../components/Footer";
import {PrimaryButton} from "../components/Button";
import CurrencyInput from 'react-currency-input';
import {PREFIX_MONEY} from "../util/constants";
import {OrderContext, Pagamento} from "../contexts/OrderContext";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {clarearCor} from "../util/color_util";
import {Parametros} from "../models/Parametros";


const ModalPagamentos = () => {

    const {isOpen, closeModal, initialData, setInitialData}: any = useContext(ModalContext)
    const {setMeioPagamento}: any = useContext(OrderContext)
    const {parametros}: {parametros: Parametros} = useContext(ApiDataContext)

    const handleClose = (e: Event) => {
        closeModal()
    }

    const [pagamentoSelecionado, setPagamentoSelecionado] = useState<string>()
    const [valorDinheiro, setValorDinheiro] = useState<number>()

    const handleValorDinheiro = (valor: string) => {
        // const valorFormatado = toNumber(valor.replace(PREFIX_MONEY, ''))
        let valorFormatado = valor.replace(PREFIX_MONEY, '')
        valorFormatado = valorFormatado.replace('.', '')
        valorFormatado = valorFormatado.replace(',', '.')
        setValorDinheiro(Number(valorFormatado))
    }

    useEffect(() => {
        if(initialData) {
            setPagamentoSelecionado(initialData.meioPagamento)
            setValorDinheiro(initialData.valor)
            setInitialData(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData])

    return (
        <ModalContextProvider>
            <ModalContainer isOpen={isOpen} onClick={handleClose}>
                <Modal isOpen={isOpen} onClick={(e: Event) => e.stopPropagation()}>
                    <CloseModal onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} size={'2x'} color={colorDark}/>
                    </CloseModal>
                    <TitleContainer>
                        <Title>Formas de Pagamento</Title>
                    </TitleContainer>
                    <Content>
                        <div>
                            <MeioPagamento onClick={() => setPagamentoSelecionado('dinheiro')} selected={pagamentoSelecionado === 'dinheiro'} color={parametros?.idWebTemplate?.cor}>
                                <FontAwesomeIcon icon={faMoneyBillAlt} size={'2x'}/>
                                <SubTitle>Dinheiro</SubTitle>
                            </MeioPagamento>
                            <MeioPagamento onClick={() => setPagamentoSelecionado('cartao')} selected={pagamentoSelecionado === 'cartao'} color={parametros?.idWebTemplate?.cor}>
                                <FontAwesomeIcon icon={faCreditCard} size={'2x'}/>
                                <SubTitle>Cartão</SubTitle>
                            </MeioPagamento>
                        </div>
                        <Footer>
                            {pagamentoSelecionado === 'dinheiro' ?
                                <>
                                    <SubTitle style={{fontWeight: 'normal'}}>Troco para quanto?</SubTitle>
                                    <InputMonetario
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                        prefix={PREFIX_MONEY}
                                        value={valorDinheiro}
                                        // inputType={'number'}
                                        onChangeEvent={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleValorDinheiro(event.target.value)
                                        }}
                                    />
                                </> : null}
                            <ConfirmarPagamentoButton color={parametros?.idWebTemplate?.cor} onClick={() => {
                                const pagamento: Pagamento = {meioPagamento: pagamentoSelecionado, valor: valorDinheiro}
                                setMeioPagamento(pagamento)
                                closeModal()
                            }} disabled={!pagamentoSelecionado || (pagamentoSelecionado === 'dinheiro' && !valorDinheiro)}>
                                <span>Confirmar forma de pagamento</span>
                            </ConfirmarPagamentoButton>
                        </Footer>
                    </Content>
                </Modal>
            </ModalContainer>
        </ModalContextProvider>
    );
};

export default ModalPagamentos;

const ModalContainer: any = styled.div`
position: fixed;
bottom: 0;
left: 0;
background-color: rgba(0, 0, 0, .4);
overflow: hidden;
${({isOpen}: any) => isOpen ? ({
    width: '100vw',
    height: '100vh',
    opacity: 1,
    transition: 'opacity .5s',
}) : ({
    pointerEvents: 'none',
    width: '0',
    height: '0',
    opacity: 0,
    transition: 'opacity .5s',
})}
`

const Modal: any = styled.div`
background-color: #fff;
height: 100vh;
width: 100vw;
position: fixed;
bottom: 0;
left: 0;
border-top-left-radius: 1rem;
border-top-right-radius: 1rem;

${({isOpen}: any) => isOpen ? ({
    maxHeight: '85vh',
    transition: 'all .5s',
}) : ({
    maxHeight: '0',
    transition: 'all .5s',
})};
`

const CloseModal: any = styled.div`
width: 5rem;
height: 5rem;
border-radius: 50%;
background-color: #FFF;
position: absolute;
top: -2.5rem;
right: 1rem;
box-shadow: 0 0 .3rem .2rem rgba(0, 0, 0, .2);
${Row};
justify-content: center;
`

const TitleContainer = styled.div`
${Row};
justify-content: center;
padding: 2.5rem;
border-bottom: .2rem solid ${bodyColorDark};
`

const Content = styled.div`
${Column};
`

const MeioPagamento: any = styled.div`
${Row};
justify-content: flex-start;
font-size: 1.4rem;
padding: 2rem;
user-select: none;
h2 {
margin-left: 2rem;
}

${({selected, color}: any) => selected ? ({
    color: '#fff',
    backgroundColor: clarearCor(color),
}) : ({
    color: colorDarkMedium,
    backgroundColor: '#fff',
})};
`

const Footer = styled(CardFooter)`
${Column};
align-items: flex-start;
padding: 2rem;
input {
margin-bottom: 2rem;
}
`

const ConfirmarPagamentoButton = styled(PrimaryButton)`
width: 100%;
padding: 1rem .8rem;
`

const InputMonetario: any = styled(CurrencyInput)`
width: 100%;
padding: 1rem;
border-radius: .2rem;
border: .1rem solid ${bodyColorDark};

&:focus {
border: .1rem solid ${secundaryColor};
}
`
