import React, {useContext} from 'react';
import styled from "styled-components";
import {darkTextColor, SubTitle, textColorWhite, Title} from "../styles/typography";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faMotorcycle, faMapMarkerAlt, faClock} from '@fortawesome/free-solid-svg-icons'
import {FlatGreenButton} from "../components/Button";
import MaxWidth from "./MaxWidth";
import Drawer from "./Drawer";
import {DrawerContext} from "../contexts/DrawerContext";
import {ApiDataContext} from "../contexts/ApiDataContext";
import {clarearCor} from "../util/color_util";
import {Parametros} from "../models/Parametros";

const Topbar = () => {

    const {openDrawer}: any = useContext(DrawerContext)

    const {parametros}: {parametros: Parametros} = useContext(ApiDataContext)

    return (
        <>
            <Drawer />
            <TopbarStyle>
                <TopTopbar color={parametros?.idWebTemplate?.cor}>
                    <FontAwesomeIcon icon={faBars} size={'2x'} onClick={openDrawer}/>
                    <Title>Super Zapp</Title>
                </TopTopbar>
                <DownSide color={parametros?.idWebTemplate?.cor}>
                    <MaxWidth>
                    <CompanyLogoAndName>
                        <Logo src={parametros?.idWebTemplate?.logo || 'https://toqweb-images.s3.amazonaws.com/logo/icon-192x192.png'}/>
                        <SubTitle>{parametros?.nomeLoja}</SubTitle>
                    </CompanyLogoAndName>
                    <Infos>
                        <div>
                            <Info>
                                <FontAwesomeIcon icon={faMotorcycle}/>
                                <InfoText>Entrega</InfoText>
                            </Info>
                            <Info>
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                <InfoText>{parametros?.enderecoLoja}</InfoText>
                            </Info>
                        </div>
                        <ButtonContainer>
                            <FlatGreenButton color={parametros?.idWebTemplate?.cor}><FontAwesomeIcon icon={faClock}/> ABERTO</FlatGreenButton>
                        </ButtonContainer>
                    </Infos>
                    </MaxWidth>
                </DownSide>
            </TopbarStyle>
        </>
    );
};

const TopbarStyle = styled.div`
${textColorWhite}; 
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 2rem;
`

export const TopTopbar = styled.div`
padding: .5rem 1rem;
background-color: ${({color}) => clarearCor(color)};
width: 100%;
display: grid;
grid-template-columns: repeat(3, 1fr);
align-items: center;
`

const DownSide = styled.div`
padding: 2rem;
background-color: ${({color}) => color};
width: 100%;
min-height: 18vh;
font-size: 2rem;
`

const CompanyLogoAndName = styled(MaxWidth)`
display: grid;
grid-template-columns: 5rem 1fr;
grid-gap: 2rem;
`

const Logo = styled.img`
width: 100%;
`

const Infos = styled.div`
display: grid;
grid-template-columns: 2fr 10rem;
padding-top: 2rem;
align-items: start;
`

const Info = styled.div`
font-size: 1.4rem;
${darkTextColor};
margin-bottom: 1rem;
color: #fff;
`

const InfoText = styled.span`
margin-left: 1rem;
`

const ButtonContainer = styled.div`

`

export default Topbar;
