import React, {createContext, useState} from "react";
import {Pagamento} from "./OrderContext";

// @ts-ignore
export const ModalContext = createContext()

const ModalContextProvider = ({children}: any) => {

    const [isOpen, setIsOpen] = useState(false)
    const [initialData, setInitialData] = useState<Pagamento>()

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <ModalContext.Provider value={{isOpen, openModal, closeModal, initialData, setInitialData}}>
            {children}
        </ModalContext.Provider>
    )

}

export default ModalContextProvider
